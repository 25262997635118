import React from "react"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Onecol from '../components/onecol'
import Twocol from '../components/twocol'
import Cards from '../components/cards'
import ImageText from '../components/imagetext'
import ImageCTA from '../components/imageCTA'
import ButtonCTA from '../components/buttonCTA'
import { graphql } from 'gatsby'

import Banner from '../components/banner'
import PrintingBanner from '../images/printing-services-banner.jpg'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import BusinessCards from '../images/business-cards.png'
import Stickers from '../images/stickers.jpg'
import Sign from '../images/a-frame-sign.jpg'

import { Book } from 'tabler-icons-react'
import { File } from 'tabler-icons-react'
import { FileHorizontal } from 'tabler-icons-react'
import { Id } from 'tabler-icons-react'
import { Mail } from 'tabler-icons-react'

export default function Printing({data}) {

    return (
    <Layout>
        <SEO 
        title="Printing" description="We work with you to take your vision to print! From brochures, flyers & signs to business cards, invitations & more, connect with the PROS."
        />

        <Banner
          title="Printing Services"
          description="Professionally printed products with friendly service &amp; quality you can count on."
          img={PrintingBanner}
        />
  
        {data.allStrapiPrinting.edges.map(({ node }) => (
          
        <>
        <Onecol
        key={node.printing_intro.id}
        title={node.printing_intro.one_col_title}
        description={node.printing_intro.one_col_description}
        />

        <Cards 
        key={node.print_page_cards.id}
        linkOne="/printing/brochures"
        textLeft={false}
        iconOne={
            <Book
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleOne={node.print_page_cards.title_one}
        linkOne="/printing/brochures"
        descriptionOne={node.print_page_cards.description_one}
        linkTwo="/printing/flyers"
        iconTwo={
            <File
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleTwo={node.print_page_cards.title_two}
        linkTwo="/printing/flyers"
        descriptionTwo={node.print_page_cards.description_two}
        linkThree="/printing/posters-signage"
        iconThree={
            <FileHorizontal
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleThree={node.print_page_cards.title_three}
        descriptionThree={node.print_page_cards.description_3}
        />      

        <ButtonCTA />

        <Twocol
        key={node.printing_two_col.id}
        image={BusinessCards}
        grey={true}
        textLeft={true}
        intro={node.printing_two_col.two_col_intro}
        iconOne={
            <Id
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleOne={node.printing_two_col.title_one}
        linkOne="/printing/business-cards"
        descriptionOne={node.printing_two_col.description_one}
        iconTwo={
            <Mail
                size={40}
                strokeWidth={2}
                color={'#DD2E39'}
            />            
        }
        titleTwo={node.printing_two_col.title_two}
        linkTwo="/printing/office-stationary"
        descriptionTwo={node.printing_two_col.description_two}
        />

        <ImageText 
        key={node.printing_two_col_plain.id}
        background={Stickers}
        textLeft={false}
        title={node.printing_two_col_plain.two_col_title}
        description={node.printing_two_col_plain.two_col_description}
        />

        <ImageCTA
        title="Give your business eye-catching, well designed signage."
        image={Sign}
        link="/contact-us"
        linkText="inquire today"
        />
        </>
    ))}  
      </Layout>
    )
    
  }

// export default Printing

export const query = graphql`
query Printing {
  allStrapiPrinting {
    edges {
      node {
        printing_hero {
          id
          hero_title
          hero_description
        }
        printing_intro {
          id
          one_col_title
          one_col_description
        }
        print_page_cards {
          id
          title_one
          description_one
          title_two
          description_two
          title_three
          description_3
        }
        printing_two_col {
          id
          two_col_intro
          title_one
          description_one
          title_two
          description_two
        }
        printing_two_col_plain {
          id
          two_col_title
          two_col_description
        }
      }
    }
  }
}

`